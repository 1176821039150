import React, { useState } from 'react';
import BackgroundImage from 'gatsby-background-image';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Hyperlink from 'src/components/_shared/hyperlink/hyperlink';
import {
  InteractivePhotoContainer, InteractivePhotoBG, DotsContainer, BackgorundText, InteractivePhotoBGContainer, CloseCurtain,
} from './styles';
import HoverDot from '../hoverDot/hoverDot.jsx';
import AnimatedPhotoModal from '../animatedPhotoModal/animatedPhotoModal.jsx';

const InteractivePhoto = ({
  arrayDot, modals, photos, bgPhoto, body, textPosition, 
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [modalPhoto, setModalPhoto] = useState(null);
  const [activeInfo, setActiveInfo] = useState(null);

  const serchForPhoto = (photosArray, ind) => {
    const serchedPhoto = photosArray.filter((x) => {
      if (x[ind]) {
        return x;
      }
    });
    return serchedPhoto;
  };

  const getModalInfo = (modalArrays, ind) => {
    const serchedArray = modalArrays.filter((x) => {
      if (x.node.frontmatter.selector === ind) {
        return x;
      }
    });
    return serchedArray;
  };

  const setModal = (ind) => {
    const serchedPhoto = serchForPhoto(photos, ind);
    const modalInfo = getModalInfo(modals, ind);
    setOpenModal(true);
    setModalPhoto(serchedPhoto[0][ind]);
    setActiveInfo(modalInfo);
  };

  const closeModal = () => {
    setOpenModal(false);
    setModalPhoto(null);
    setActiveInfo(null);
  };

  const allDots = arrayDot.map((x, index) => (
    <HoverDot
      positionLeft={x.positionLeft}
      positionTop={x.positionTop}
      insideText={x.insideText}
      clickDot={() => { setModal(x.identifier); }}
      key={`Hover__${index}`}
    />
  ));

  return (
    <>
      <InteractivePhotoContainer>
        <InteractivePhotoBGContainer openModal={openModal}>
          <BackgroundImage fluid={bgPhoto}>
            <InteractivePhotoBG />
          </BackgroundImage>
        </InteractivePhotoBGContainer>
        <BackgorundText textPosition={textPosition} openModal={openModal}>
          <MDXProvider components={{
            Hyperlink,
            p: (props) => <p {...props} tabIndex="0" />,
          }}
          >
            <MDXRenderer>{body}</MDXRenderer>
          </MDXProvider>
        </BackgorundText>
        <DotsContainer showDot={openModal}>
          {allDots}
        </DotsContainer>
        <AnimatedPhotoModal
          openModal={openModal}
          clickArrow={closeModal}
          modalBody={activeInfo}
          modalPhoto={modalPhoto}
        />
        <CloseCurtain openModal={openModal} onClick={closeModal}/>
      </InteractivePhotoContainer>
    </>
  );
};
export default InteractivePhoto;
