import React, { createRef, useEffect } from 'react';
import BackgroundImage from 'gatsby-background-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

import {
  ModalContainer, ArrowDot, AnimatedHoverCircle, Arrow, ModalContent, AnimationDiv,
}
  from './styles';

const AnimatedPhotoModal = ({
  modalBody, modalPhoto, bgPhoto, openModal, clickArrow,
}) => {

  const modalContainer = createRef();

  const closeModal = (event) => {
    if (event.key === "Escape") {
      clickArrow();
    }
  }

  const closeModalIconHandler = (event) => {
    if (event.key === "Enter") {
      clickArrow();
    }
  }
  useEffect(() => {
    if (modalContainer.current) {
      modalContainer.current.focus();
    }
  });

  const body = modalBody ? modalBody[0].node.body : '';
  const style = {
    transform: 'rotate(45deg)',
  }
  return (
    <>
      <ModalContainer
        bgPhoto={bgPhoto}
        openModal={openModal}
        ref={modalContainer}
        onKeyDown={closeModal}
      >
        {(modalBody)
          && (
          <AnimationDiv>
            <BackgroundImage fluid={modalPhoto}>
              <ModalContent>
                <MDXProvider components={{
                  p: props => <p {...props} tabIndex="0" />
                }}>
                  <MDXRenderer>{body}</MDXRenderer>
                </MDXProvider>
              </ModalContent>
            </BackgroundImage>
          </AnimationDiv>
          )}
        {(openModal)
          && (
          <ArrowDot
            onClick={clickArrow}
            onKeyDown={closeModalIconHandler}
          >
            <AnimatedHoverCircle />
            <Arrow />
            <Arrow style={style}/>
          </ArrowDot>
          )}
      </ModalContainer>
    </>
  );
};
export default AnimatedPhotoModal;
