import styled, { keyframes } from 'styled-components';

export const HoverDotContainer = styled.div`
  position: absolute;
  overflow-wrap: normal;
  ${({ positionLeft }) => positionLeft && `
    left: ${positionLeft};
 `}
  ${({ positionTop }) => positionTop && `
     top: ${positionTop};
  `}
`;

const showDots = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

export const HoverDotMain = styled.div.attrs((label) => ({ tabIndex: 0, ariaLabel: label }))`
  width: 1.875rem;
  height: 1.875rem;
  border: 0.45rem solid #ff0000;
  border-radius: 50%;
  background-color: #f3f2f0;
  z-index: 10;
  transition: 0.45s;
  cursor: pointer;
  animation: ${showDots} 0.5s cubic-bezier(0.820, 0.000, 0.195, 1.000);
`;

export const HoverDotText = styled.div`
  opacity: ${({ alternative }) => (alternative ? 1 : 0)};
  height: ${({ alternative }) => (alternative ? '4.9rem' : '2.5625rem')} ;
  width: ${({ alternative }) => (alternative ? '4.9rem' : 'auto')};
  border-radius: 0.2rem;
  font-size: 1rem;
  position: relative;
  background-color: ${({ alternative, main }) => (alternative && main ? 'rgba(58,117,196,0.54)' : alternative ? 'transparent' : '#fdfdfd')};
  padding: ${({ alternative }) => (alternative ? '0 0 0 1.875rem' : '0 3.1875rem 0 1.8125rem')};
  transition: 0.45s;
  display: flex;
  align-items: center;
  white-space: nowrap;
  border-radius: ${({ alternative }) => (alternative ? '50%' : '0.2rem')};
  right: ${({ alternative }) => (alternative ? '1.5rem' : '-2rem')};
  top: ${({ alternative }) => (alternative ? '-1.5rem' : '-0.4rem')};
  z-index: 3;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 700;
  &::after {
    content: '${({ text }) => text}';
    color: white;
    position: absolute;
    top: 3rem;
    left: 4rem;
    display: ${({ alternative }) => (alternative ? 'block' : 'none')};
    font-family: 'Abril Fatface', 'Prada';
    font-size: ${({ main, alternative }) => (alternative && main ? '6rem' : '3rem')};
  }

`;

export const HoverDotHoverContainer = styled.div`
  width: 1.875rem;
  height: 1.875rem;
  position: absolute;
  z-index: 11;
  cursor: pointer;
  &:hover ${HoverDotMain} {
    border: 0.8rem solid #ff0000;
    transform: scale(0.47);
  }
  &:hover~div{
    opacity: 1;
  }
  &::before {
  content: '${({ tooltip }) => tooltip}';
  position: absolute;
  /* top: -50%; */
  right: 150%;
  display: ${({ tooltip }) => (tooltip ? 'block' : 'none')};
  color: black;
  font-family: 'Red Hat Display', 'Open Sans';
  background: white;
  padding: 0.3rem 1rem;
  white-space: nowrap;
  border-radius: 3px;
  font-weight: 500;
    pointer-events: none;
    opacity: 0;
    transition: 0.5s;
    z-index: 3;
  }
  &:hover::before {
    opacity: 1;
  }
`;
