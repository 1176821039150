import styled, { keyframes, css } from 'styled-components';
import Img from 'gatsby-image';
import { fonts } from '../../../../../globals';

const enter = keyframes`
    0%{
        transform: translate3d(0,40%,0);
        opacity: 0;
    }
    100%{
        transform: translate3d(0,0,0);
        opacity: 1;
    }
`;

const exit = keyframes`
    0%{
        transform: translate3d(0,0,0);
        opacity: 1;
    }
    100%{
        transform: translate3d(0,40%,0);
        opacity: 0;
    }
`;

const enterAnimation = ({ visible }) => {
  if (visible) {
    return css`
      animation: ${enter} 0.8s cubic-bezier(0.78, 0, 0.235, 1) 200ms forwards;
    `;
  }
  return css`
    animation: ${exit} 0.8s cubic-bezier(0.78, 0, 0.235, 1) forwards;
  `;
};

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
`;

export const PhotoWrapper = styled.div`
  position: relative;
  margin-bottom: 2rem;
`;

export const PhotoPerson = styled.span`
position: absolute;
    top: 100%;
    left: 0;
    color: #f9f8f3;
    font-family: "Abril Fatface";
    font-size: 1.875rem;
    font-weight: 400;
    line-height: 1.875rem;
    letter-spacing: 1.39px;
    transform: translateY(-50%);
`;

export const PhotoImg = styled(Img)`
  /* max-width: 80%; */
  margin-left: 1.375rem;
`;

export const WrapperText = styled.div`
  ${{ ...fonts.popup }}
  font-weight: 400;
  text-align: left;
  padding: 1rem 0;
  color: ${({ colorText }) => colorText};
  width: ${({ widthText }) => `${widthText}%`};
  opacity: 0;
  ${enterAnimation};
  margin-left: ${({ fullScreen }) => fullScreen && '6.6%'};
  line-height: 1.5rem;
  width: 80%;
`;
