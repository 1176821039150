import React from 'react';
import {
  HoverDotContainer, HoverDotText, HoverDotMain, HoverDotHoverContainer,
} from './styles';

const HoverDot = ({
  positionLeft, positionTop, clickDot, insideText, alternative, main, tooltip,
}) => {
  const openModalContainer = (event) => {
    if (event.key === 'Enter') {
      clickDot();
    }
  };
  return (
    <>
      <HoverDotContainer
        positionLeft={positionLeft}
        positionTop={positionTop}
        onClick={clickDot}
        onKeyDown={openModalContainer}
        role="button"
        tabindex="0"
        aria-label={insideText}
      >
        <HoverDotHoverContainer tooltip={tooltip}>
          <HoverDotMain label={insideText} />
        </HoverDotHoverContainer>
        <HoverDotText text={insideText} alternative={alternative} main={main}>
          {!alternative && insideText}
        </HoverDotText>
      </HoverDotContainer>
    </>
  );
};
export default HoverDot;
