import React from 'react';
import LeftColContent from 'src/components/desktop/leftColContent/leftColContent';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import BackgroundAnimation from 'src/components/desktop/backgroundAnimation/backgroundAnimation';
import { useStaticQuery, graphql } from 'gatsby';
import Video from 'videos/chapter_2/sub_1/2-1-1-bg.webm';
import VideoMP4 from 'videos/chapter_2/sub_1/2-1-1-bg.mp4';
import { SlideLarge } from '../../_styles';


const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
  query {
    scenelayer1: file(relativePath: { eq: "chapter_2/sub_1/2-1-1-parallax.png"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  scenelayer2: file(relativePath: { eq: "chapter_2/sub_1/2-1-1-overlay.png"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  modal: file(relativePath: { eq: "chapter_2/sub_1/2-1-1-modal.jpg"}) {
    childImageSharp {
      fixed(width: 506, height: 762)  {
        ...GatsbyImageSharpFixed_withWebp_noBase64
        width
      }
    }
  }
  }
  `);
  return (
    <SlideLarge>
      <ParallaxMordo>
        <Mordo
          mainscene
          move={{ x: -20, y: -10 }}
          background={{ color: 'rgba(19,35,46, 0.25)' }}
        >
          <BackgroundAnimation src={Video} mp4={VideoMP4} responsive />
        </Mordo>

        <Mordo
          scenelayer
          scene={1}
          move={{ x: 10, y: 20 }}
          background={{ image: assetQuery.scenelayer1.childImageSharp.fluid }}
          opacity={1}
        />
        <Mordo
          scenelayer
          scene={2}
          move={{ x: 10, y: 20 }}
          background={{ image: assetQuery.scenelayer2.childImageSharp.fluid }}
          opacity={1}
        />
        <Mordo
          flexi
          scene={3}
          move={{ x: -20, y: -10 }}
        >
          <div css={{
            alignSelf: 'center', width: '100%', margin: '6% auto 0',
          }}
          >
            <LeftColContent body={query.mdx.body} images={[assetQuery.modal.childImageSharp.fixed]} />

          </div>
        </Mordo>
      </ParallaxMordo>
    </SlideLarge>

  );
};


export default Slide;
