import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SliderContent = ({
  children, center = true, infinite = false, slidesToShow = 1,
}) => {
  const settings = {
    dots: false,
    infinite,
    speed: 500,
    slidesToShow,
    slidesToScroll: 1,
    centerMode: center,
    arrows: false,
  };
  return <Slider {...settings}>{children}</Slider>;
};

export default SliderContent;
