import React from 'react';
import TextAnimation from 'src/components/mobile/textAnimation/textAnimation';
import BackgroundPhoto from 'src/components/mobile/backgroundPhoto/backgroundPhoto';
import { BlockContainer } from '../../_styles';

const Slide = ({ query }) => (
  <BlockContainer
  // height="1100px"
  >
    {/* <BackgroundAnimation> */}
    <BackgroundPhoto fixed={query.file.childImageSharp.fixed} enter alt="slide-photo">
      <TextAnimation body={query.mdx.body} containerStyle={{
        // justifyContent: 'space-between', 
        marginTop: '29rem'
      }} />
    </BackgroundPhoto>
    {/* </BackgroundAnimation>  */}
  </BlockContainer>
);


export default Slide;
