import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import InteractivePhoto from 'src/components/desktop/interactivePhoto/interactivePhoto';
import { SlideLarge, FlexWrapper } from '../../_styles';

const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
    query {
      maria: file(relativePath: { eq: "chapter_2/sub_1/maria.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      alexandra: file(relativePath: { eq: "chapter_2/sub_1/alexandra.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      tatiana: file(relativePath: { eq: "chapter_2/sub_1/tatiana.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      anastasia: file(relativePath: { eq: "chapter_2/sub_1/anastasia.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      olga: file(relativePath: { eq: "chapter_2/sub_1/olga.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      nicholas: file(relativePath: { eq: "chapter_2/sub_1/nicholas.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      alexei: file(relativePath: { eq: "chapter_2/sub_1/alexei.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      bgPhoto: file(relativePath: { eq: "chapter_2/sub_1/2-1-2-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const photos = [
    { alexandraRomanov: assetQuery.alexandra.childImageSharp.fluid },
    { mariaRomanov: assetQuery.maria.childImageSharp.fluid },
    { olgaRomanov: assetQuery.olga.childImageSharp.fluid },
    { tatianaRomanov: assetQuery.tatiana.childImageSharp.fluid },
    { anastasiaRomanov: assetQuery.anastasia.childImageSharp.fluid },
    { alexeiRomanov: assetQuery.alexei.childImageSharp.fluid },
    { nicholasRomanov: assetQuery.nicholas.childImageSharp.fluid },
  ];

  const textPosition = {
    left: 'unset',
    right: '5vw',
    bottom: 'unset',
    top: '20vh',
    zIndex: 2,
  };

  return (
    <SlideLarge>
      <FlexWrapper>
        <InteractivePhoto
          body={query.body.body}
          bgPhoto={assetQuery.bgPhoto.childImageSharp.fluid}
          arrayDot={query.hoverDot.exports.hoverInfo}
          modals={query.activeModals.edges}
          photos={photos}
          textPosition={textPosition}
        />
      </FlexWrapper>
    </SlideLarge>
  );
};

export default Slide;
