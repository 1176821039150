import styled from 'styled-components';
import { fonts, colors } from '../../../../../globals';

export const InteractivePhotoContainer = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

export const InteractivePhotoBGContainer = styled.div`
  width: 100vw;
  height: 100vh;
  transition: 0.5s;
 ${({ openModal }) => openModal && `
    transform: translateX(-20vw);
 `}
`;
export const CloseCurtain = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  ${({ openModal }) => openModal && `
    display: block;
  `}
`;

export const InteractivePhotoBG = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`;

export const DotsContainer = styled.div`
  transition: 0.5s;
  opacity: 1;
  position: absolute;
  width: 100vW;
  height: 100vh;
  top: 0;
  left: 0;
  ${({ showDot }) => showDot && `
    opacity: 0;
    transform: translateX(-20vw);
 `}
`;

export const BackgorundText = styled.div`
  position: absolute;
  width: 33vw;
  transition: 0.5s;
  text-align: right;
  ${({ openModal }) => openModal && `
    opacity: 0;
    transform: translateX(-20vw);
  `}
  ${({ textPosition }) => textPosition && `
    right: ${textPosition.right};
    top: ${textPosition.top};
    left: ${textPosition.left};
    bottom: ${textPosition.bottom};
    z-index: ${textPosition.zIndex};
 `}

   & h2 {
     ${{ ...fonts.headline_2 }}
     ${{ ...colors.light }}
     text-align: right;
     top: 60px;
     font-size: 4.375rem;
     line-height: 4.375rem;
   }

   & h3 {
     ${{ ...fonts.headline_3a }}
     ${{ ...colors.light }}
     text-align: right;
     position: relative;
   }

   & p, & > div > p {
     ${{ ...fonts.paragraf }}
     ${{ ...colors.light }}
     text-align: right;
     max-width: 398px;
     display: inline;
     width: 29vw;
   }
   span {
     font-size: 1rem;
     line-height: 1.62rem;
     letter-spacing: 0.03rem;
   }
`;
