import styled, { keyframes } from 'styled-components';

export const ModalContainer = styled.div`
  width: 60vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  right:-60vw;
  background-color: #000;
  transition: 0.5s;
  z-index: 10;
  ${({ bgPhoto }) => bgPhoto && `
    background-image: url(${bgPhoto})
 `}
 ${({ openModal }) => openModal && `
   right:0;
`}
 display: flex;
`;

export const AnimatedHoverCircle = styled.div`
  width: 2.9375rem;
  height: 2.9375rem;
  background-color: transparent;
  border: 1px solid #ebe6e6;
  visibility: hidden;
  z-index: 100;
  position: absolute;
  border-radius: 100%;
`;

export const ArrowDot = styled.div.attrs(() => ({ tabIndex: 0 }))`
  width: 2.9375rem;
  height: 2.9375rem;
  background-color: #df3242;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-evenly;
  padding: 0.35rem;
  align-items: center;
  cursor: pointer;
  &:hover ${AnimatedHoverCircle} {
    transition: 1s cubic-bezier(0.820, 0.000, 0.195, 1.000);
    visibility: visible;
    transform: scale(2);
    opacity: 0;
  }
`;

export const Arrow = styled.div`
  width: 1.5rem;
  height: 2px;
  background-color: #ebe6e6;
  position: absolute;
  transform: rotate(-45deg);
`;

const showModal = keyframes`
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
`;

export const ModalContent = styled.div`
  color: #f9f8f3;
  font-family: 'Abril Fatface', sans-serif;
  width: 60vw;
  height: 100vh;
  h2 {
    font-family: 'Abril Fatface', sans-serif;
    width: 33rem;
    margin: 23vh 0 0 10vw;
    font-size: 5.625rem;
    font-weight: 400;
  }
  h3 {
    font-family: 'Abril Fatface', sans-serif;
    width: 33rem;
    margin: 5vh 0 0 10vw;
    font-weight: 400;
    font-size: 1.5rem;
  }
  p {
    width: 33rem;
    margin: 3vh 0 0 10vw;
    font-size: 1rem;
    font-weight: 400;
    font-family: "Red Hat Display", sans-serif;
    line-height: 1.7;
  }
`;

export const AnimationDiv = styled.div`
  animation: ${showModal} 1.5s cubic-bezier(0.820, 0.000, 0.195, 1.000) forwards;
  display: flex;
`;
