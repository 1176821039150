import React from 'react';
import SignedPerson from 'src/components/mobile/signedPerson/signedPerson.jsx';

const Slide = ({ query }) => (
  <>
    <SignedPerson
      photo={query.file.childImageSharp.fixed}
      text={query.mdx.body}
      personName={query.mdx.frontmatter.person}
      colorText="#fff"
      alt="slide-photo"
    />
  </>
);

export default Slide;
